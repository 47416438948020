import { createRouter, createWebHistory } from '@ionic/vue-router';
import TabsPage from '../views/TabsPage.vue';

const routes = [
  {
    path: '/',
    redirect: '/tabs/images',
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/images',
      },
      {
        path: 'images',
        name: 'images',
        component: () => import('@/views/UserImages.vue'),
      },
      {
        path: 'takephoto',
        name: 'takephoto',
        component: () => import('@/views/TakePhoto.vue'),
      },
      {
        path: 'apptutorial',
        name: 'apptutorial',
        component: () => import('@/views/AppTutorials.vue'),
      },
      {
        path: 'imagecropper',
        name: 'imagecropper',
        component: () => import('@/views/ImageCropper.vue'),
      },
      {
        path: 'textsetup',
        name: 'textsetup',
        component: () => import('@/views/TextSetup.vue'),
      },
      {
        path: 'printimage',
        name: 'printimage',
        component: () => import('@/views/PrintImage.vue'),
      },
      {
        path: '/404',
        name: '404',
        redirect: '/tabs/images',
      },
      {
        path: '*',
        redirect: '/tabs/images',
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
